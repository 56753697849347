<template>
    <div class="invitation-friend" @scroll="onScroll">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('INVITATION_FRIEND')"
            :show-title="showHeaderTitle"
        />
        <div class="title" v-html="$translate('INVITATION_FRIEND')"></div>
        <div class="body">
            <div class="my-code" v-html="'내 초대 코드'" />
            <div class="code-container">
                <div class="row">
                    <div class="code-text" v-html="myInvitationCode" />
                    <i class="link-icon-copy material-icons-outlined" @click="copyCode">file_copy</i>
                </div>
                <div class="row m-t-20">
                    <div class="invite-btn-kakao" @click="copyCodeInKakao">
                        <img src="@/assets/images/kakaotalk.svg" />
                        <div class="m-l-6">카카오톡으로 초대</div>
                    </div>
                    <div class="invite-btn-call" @click="copyCodeInCall">
                        <img src="@/assets/images/call.svg" />
                        <div class="m-l-6">연락처로 쉽게 초대</div>
                    </div>
                </div>
            </div>
            <div class="invitation-infos">
                <div class="info-container">
                    <div class="info-point" />
                    <div class="invitation-info" v-html="$translate('INVITATION_FRIEND_INFO_1')" />
                </div>
                <div class="info-container">
                    <div class="info-point" />
                    <div class="invitation-info" v-html="$translate('INVITATION_FRIEND_INFO_2')" />
                </div>
                <div class="info-container">
                    <div class="info-point" />
                    <div class="invitation-info" v-html="$translate(`INVITATION_FRIEND_INFO_3_${gender}`)" />
                </div>
            </div>
            <div class="hr" v-if="!hasEnteredInvitationCode" />
            <div class="invitation-code" v-if="!hasEnteredInvitationCode">
                <div class="invitation-code-title" v-html="$translate('INVITATION_CODE_TITLE')" />
                <div class="invitation-code-container flex-row">
                    <input
                        class="invitation-code-input"
                        :placeholder="$translate('INVITATION_CODE_PLACEHOLDER')"
                        v-model="invitationCode"
                        @input="onInputCode"
                        style="ime-mode: inactive"
                    />
                    <div class="invitation-code-button" @click="onClickEnroll">
                        <span class="text" v-html="'입력'" />
                    </div>
                </div>
                <div class="invitation-code-comment" v-html="$translate('INVITATION_CODE_COMMENT')" />
            </div>
            <div class="hr" />
        </div>
        <div class="footer">
            <div class="invitation-points">
                <div class="point-title" v-html="$translate('INVITATION_POINT_TITLE')" />
                <div class="point-value" v-html="`<span class='f-bold'>${invitationPoint}</span>P`" />
                <div class="point-item-container">
                    <div
                        v-show="item.show"
                        @click="onClickItem(item)"
                        class="point-item flex-between"
                        :key="idx"
                        v-for="(item, idx) in items"
                    >
                        <div class="item-name" v-html="item.name" />
                        <div class="item-cost" v-html="`${item.cost}P`" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import inviteHistoryService from '@/services/invite-history'
import userService from '@/services/user'

export default {
    name: 'InvitationFriend',
    components: {},
    data: () => ({
        selectedProduct: null,
        invitationCode: null,
        invitationPoint: 0,
        invitationCount: 0,
        hasEnteredInvitationCode: true,
        showHeaderTitle: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        gender() {
            return (this.$store.getters.me || {}).gender
        },
        isFemale() {
            return this.gender === 1
        },
        myInvitationCode() {
            return this.me ? this.me.profile.invitation_code : ''
        },
        items() {
            return [
                {
                    key: 'vanilla',
                    name: this.$translate('INVITATION_POINT_ITEM_VANILLA'),
                    cost: 1,
                    show: true,
                },
                {
                    key: 'privacy',
                    name: this.$translate('INVITATION_POINT_ITEM_PRIVACY'),
                    cost: 1,
                    show: this.isFemale,
                },
                {
                    key: 'ticket',
                    name: this.$translate('INVITATION_POINT_ITEM_TICKET'),
                    cost: 1,
                    show: true,
                },
                {
                    key: 'premium',
                    name: this.$translate('INVITATION_POINT_ITEM_PREMIUM'),
                    cost: 1,
                    show: true,
                },
            ]
        },
    },
    created() {
        this.init()
    },
    beforeDestroy() {},
    methods: {
        async init() {
            this.hasEnteredInvitationCode = this.me.has_entered_invitation_code > 0
            this.invitationPoint = this.me.invitation_point
            this.invitationCount = this.me.invitation_count
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'invitationFriendPageEnter',
                },
            })
        },

        copyCode() {
            this.$copyText(this.myInvitationCode)
            this.$toast.success('클립보드에 복사되었어요!')
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'invitationFriendPageClickCopy',
                },
            })
        },
        copyCodeInCall() {
            const { app_version: version } = this.$store.getters.device
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'invitationFriendPageClickCall',
                    option: {
                        version: version,
                    },
                },
            })

            if (version < '1.2.2') {
                this.$toast.error('앱 업데이트 후 사용할 수 있어요')
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: 'https://abr.ge/h5p8s',
                })
                return
            }

            this.$nativeBridge.postMessage({
                action: 'getContactList',
            })
            // 웹에서 테스트용
            // this.$store.commit('setContacts', [
            //     {phoneNumbers: [{number: '0123'}], familyName: '홍', givenName: '길동'},
            //     {phoneNumbers: [{number: '0124-0101-203'}], familyName: '홍', givenName: '길동'},
            //     {phoneNumbers: [{number: '010913-123-10659'}], familyName: '홍', givenName: '길동'},
            //     {phoneNumbers: [{number: '0126313'}], familyName: '홍', givenName: '길동'},
            //     {phoneNumbers: [{number: '0127'}], familyName: '홍123', givenName: '길동'},
            //     {phoneNumbers: [{number: '01299'}], givenName: '길동일이리라어'},
            //     {phoneNumbers: [], familyName: '무명', givenName: '이아엉'},
            //     {phoneNumbers: [{num: '1023'}], familyName: '암것도', givenName: '나어ㅗ지마'},
            //     {phoneNumbers: [{number: '0129'}], familyName: '홍씨네'},
            // ])
            this.$stackRouter.push({
                name: 'ContactsListPage',
            })
        },
        copyCodeInKakao() {
            const { app_version: version } = this.$store.getters.device
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'invitationFriendPageClickKakao',
                    option: {
                        version: version,
                    },
                },
            })

            if (version < '1.2.6') {
                this.$toast.error('앱 업데이트 후 사용할 수 있어요')
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: 'https://abr.ge/h5p8s',
                })
                return
            }

            this.$nativeBridge.postMessage({
                action: 'kakaoLink',
                value: {
                    code: this.myInvitationCode,
                    templateId: '12650',
                },
            })
        },
        onInputCode(event) {
            this.invitationCode = event.target.value
        },
        async onClickEnroll() {
            try {
                this.loading = true
                const payload = { code: this.invitationCode }
                const data = await inviteHistoryService.create(payload)
                // this.$alert(data.msg)
                this.$toast.success(data.msg)
                this.hasEnteredInvitationCode = true
                this.$store.dispatch('loadBadges')
                this.$store.dispatch('loadMe')
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.loading = false
            }
        },
        async onClickItem(item) {
            if (this.invitationPoint - item.cost < 0) {
                this.$toast.error('추천 포인트가 부족해요')
                return
            }

            try {
                this.loading = true
                const payload = { ptype: item.key }
                const { msg } = await userService.useInvitationPoint(this.me.user_id, payload)
                this.$modal.basic({
                    title: '사용 완료',
                    body: msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                // this.$alert(msg)
                this.invitationPoint = this.invitationPoint - item.cost
                this.$store.dispatch('loadItems')
                this.$store.dispatch('loadMe')
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.loading = false
            }
        },
        onScroll(event) {
            if (event.target.scrollTop >= 52) {
                this.showHeaderTitle = true
            } else {
                this.showHeaderTitle = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.invitation-friend {
    .bottom-border {
        border-bottom: 1px solid $grey-02;
    }
    .title {
        @include f-medium;
        font-size: 24px;
        color: black;
        margin-left: 16px;
        margin-top: 12px;
    }
    .body {
        margin: 40px 16px 0 16px;

        .my-code {
            font-size: 18px;
            @include f-medium;
            color: black;
        }
        .code-container {
            margin-top: 24px;
            margin-bottom: 28px;

            .row {
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;

                .code-text {
                    font-size: 36px;
                    height: 54px;
                    @include f-bold;
                    text-align: center;
                    color: black;
                    // width: 100% !important;
                    // border: none;
                }
                .link-icon-copy {
                    width: 36px;
                    height: 36px;
                    padding: 7px;
                    background-color: $grey-02;
                    border-radius: 8px;
                    margin-left: 20px;
                }
                .invite-btn-kakao {
                    width: calc((100% - 8px) / 2);
                    height: 40px;
                    padding: 10px;
                    border-radius: 10px;
                    background-color: $yellow-kakao;
                    font-size: 14px;
                    @include f-medium;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    color: black;

                    img {
                        height: 16px;
                    }
                }

                .invite-btn-call {
                    width: calc((100% - 8px) / 2);
                    height: 40px;
                    padding: 10px;
                    margin-left: 8px;
                    border-radius: 10px;
                    background-color: $grey-09;
                    font-size: 14px;
                    @include f-medium;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    color: white;
                }
            }
        }
        .invitation-infos {
            border-radius: 16px;
            padding: 14px 16px;
            margin-top: 20px;
            border: solid 1px $grey-02;

            .info-container {
                display: flex;
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
                .info-point {
                    width: 4px;
                    height: 4px;
                    margin: 7px 8px 0px 0;
                    border-radius: 50%;
                    background-color: $grey-07;
                }
                .invitation-info {
                    height: fit-content;
                    font-size: 12px;
                    letter-spacing: -0.2px;
                    font-stretch: normal;
                    line-height: normal;
                    color: $grey-09;

                    ::v-deep span {
                        @include f-bold;
                    }
                }
            }
        }
        .invitation-code {
            .invitation-code-title {
                font-size: 18px;
                color: black;
                @include f-medium;
                margin-bottom: 20px;
                box-shadow: none;
            }
            .invitation-code-container {
                .invitation-code-input {
                    margin-right: 8px;
                    height: 48px;
                    border: solid 1px $grey-02;
                    border-radius: 8px;
                    box-shadow: none;
                    -webkit-appearance: none;
                    padding-left: 16px;
                    &:active,
                    &:focus {
                        border: solid 1px $purple-primary;
                        box-shadow: none;
                    }
                }
                .invitation-code-button {
                    .text {
                        font-size: 15px;
                        @include f-medium;
                        color: white;
                    }
                    width: 80px;
                    max-width: 80px;
                    padding: 13px;
                    height: 48px;
                    text-align: center;
                    border-radius: 10px;
                    background-color: $purple-primary;
                }
            }
            .invitation-code-comment {
                margin-top: 8px;
                @include f-regular;
                color: $grey-06;
                font-size: 12px;
            }
        }

        .hr {
            width: 100vw;
            margin-left: -16px;
            height: 1px;
            background: $grey-02;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    .footer {
        .invitation-points {
            margin-left: 16px;
            margin-right: 16px;
            margin-bottom: 20px;

            .point-title {
                font-size: 18px;
                @include f-medium;
                color: black;
                margin-bottom: 20px;
            }
            .point-value {
                font-size: 24px;
                @include f-regular;
                color: black;
                margin-bottom: 20px;
            }
            .point-item-container {
                .point-item {
                    display: flex;
                    padding: 12px 16px;
                    border-radius: 10px;
                    background-color: $grey-01;
                    border: solid 1px $grey-02;
                    height: 48px;
                    margin-bottom: 8px;

                    .item-name {
                        font-size: 15px;
                        @include f-regular;
                        color: black;
                        text-align: left;
                    }
                    .item-cost {
                        font-size: 14px;
                        @include f-bold;
                        color: white;
                        text-align: right;
                        background-color: $purple-primary;
                        border-radius: 12px;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}
</style>
